<template>
    <div>
        <div class="banner-container">
            <img class="career-img" src="../../assets/image/career/career-banner.jpg" />
            <p class="join-title">Join Our Team</p>
        </div>
        <el-breadcrumb class="breadcrumb-container" separator="/">
            <el-breadcrumb-item class="breadcrumb-item" :to="{ path: '/career' }">Career</el-breadcrumb-item>
            <el-breadcrumb-item class="breadcrumb-item">IT Manager</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="job-container">
            <p class="title">IT Manager</p>
            <div class="description-container">
                <p class="subtitle"> Job Responsibilities</p>
                <ul class="requirement-list">
                    <li class="list-details"> Oversee the development and maintenance of the current IT system in all functional areas</li>
                    <li class="list-details"> Plan and implement a scalable IT Platform to support the business growth strategies and regulatory changes particularly in the areas of tech stack and architecture.
                        Information Security, Quality Assurance, Audit Compliance, and IT Services – Develop and comply with IT governance guidelines, provide a procedural driven and transparent working environment</li>
                    <li class="list-details"> Strategic Planning - Develop IT department mid-term/long-term business plan and roadmap to align with the company's mid-term/ long-term KPIs and business plan</li>
                    <li class="list-details"> Business Management Role - To support, coach, train, motivate, and to drive a development as well as a backup plan for all personnel of IT department</li>
                    <li class="list-details"> Manage IT headcounts and expense budget in line with the approved budget Coordination and communication with other departments</li>
                </ul>
                <p class="salary">SALARY: NEGOTIABLE</p>
                <p class="subtitle">Job Requirements</p>
                <ul class="requirement-list">
                    <li class="list-details"> Education: Master Degree or above in the field of Computer Science, IT or in other related fields.</li>
                    <li class="list-details"> Major in Computer Science, Information Technology, Business, Software development.</li>
                    <li class="list-details"> Work experience: At least 7 years of leadership experience in an enterprise IT environment.</li>
                    <li class="list-details"> Skill & Knowledge: experience in organizational management, process improvement successes, and keen insights regarding technology and business trends.</li>
                </ul>
            </div>

            <div class="description-container">
                <p class="subtitle">How to Apply</p>
                <p class="requirement-list">Interested candidates please submit your CV/resume with a photo and accompanied by cover letter and any other supporting documents to HR Department:</p>
                <div class="hr-contact">
                    <p class="hr-contact-details">Contact Person : HR Department</p>
                    <p class="hr-contact-details">Email: hr@u-pay.com</p>
                    <p class="hr-contact-details">Contact: 069 931 888 / 077 266 123 </p>
                    <p class="hr-contact-details">Website: www.u-pay.com</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  .career-img {
    width: 100%;
  }
  .join-title {
    color: white;
    position: absolute;
    bottom: 3%;
    left: 4%;
    font-size: 2.2rem;
    font-weight: bolder;
  }
}
.job-container {
    display: table;
    margin-right: auto;
    margin-left: auto;
    padding: 3% 25% 3% 25%;
    .title {
        color: #0277E3;
        text-align: center;
        font-size: 1.55rem;
        font-weight: bold;
    }
}

.description-container{
    padding-top: 8%;
    .subtitle {
        color: black;
        text-align: center;
        font-size: 1.35rem;
        font-weight: bold;
    }
    .requirement-list{
        margin: 3% 0% 5% 0%;

        .list-details {
            margin-bottom: 0.5%;
            display: list-item;
            list-style-type: disc;
        }
    }
    .salary{
        font-weight: bolder;
    }

    .hr-contact .hr-contact-details {
        margin-bottom: 3%;
        font-weight: bold;
    }
}
.breadcrumb-container {
    margin : 3% 3%;
}

.breadcrumb-item {
    color: darkgray;
    font-weight: 600;
    font-size: 1.0rem;
}

@media only screen and (max-width: 600px) {
    .job-container {
        display: table;
        margin-right: auto;
        margin-left: auto;
        padding: 3% 5% 3% 5%;
        .title {
            color: #0277E3;
            text-align: center;
            font-size: 1.10rem;
            font-weight: bold;
        }
    }
}
</style>
